<template>
  <container>
    <page-header centered>
      <h1>Add Your Team</h1>
    </page-header>
    <loading v-if="loading" :loading="loading" />
    <grid v-else centered stackable doubling>
      <grid-column
        v-for="option in hrisImportOptions"
        :key="option.provider"
        four
      >
        <card
          fluid
          centered
          borderless
          class="integration"
          @click.native="selectHris(option.provider)"
        >
          <span>
            <g-image
              class="integration-img"
              centered
              :size="null"
              :src="option.src"
              :alt="option.provider"
            />
          </span>
        </card>
      </grid-column>
      <grid-column centered sixteen>
        <card
          fluid
          centered
          borderless
          class="other"
          @click.native="selectHris('other')"
        >
          <span class="other-card">Select From 80+ Other HR Platforms</span>
        </card>
      </grid-column>
      <grid-column centered sixteen>
        <h2>Other Import Options</h2>
      </grid-column>
      <grid-column four>
        <card
          fluid
          centered
          borderless
          class="integration"
          @click.native="() => selectSlack()"
        >
          <span>
            <g-image
              centered
              class="integration-img"
              :size="null"
              :src="slackLogo"
              alt="Slack"
            />
          </span>
          <card-description> Name + Email Only </card-description>
        </card>
      </grid-column>
      <grid-column four>
        <card
          fluid
          centered
          borderless
          class="integration"
          @click.native="() => selectSpreadsheet()"
        >
          <span>
            <g-image
              centered
              class="integration-img"
              :size="null"
              :src="spreadsheetImage"
              alt="Spreadsheet"
            />
          </span>
          <card-description> Spreadsheet </card-description>
        </card>
      </grid-column>
    </grid>
    <finch-connect
      ref="finch"
      @on-success="exchangeCode"
      @on-error="(error) => toast.error(error)"
    />
  </container>
</template>

<script>
import pageHeader from '@/components/v2/page_header.vue'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import card from '@/components/v2/card/card.vue'
import cardDescription from '@/components/v2/card/description.vue'
import gImage from '@/components/v2/image.vue'
import container from '@/components/v2/container.vue'
import loading from '@/components/v2/loading.vue'

import slackLogo from '@/assets/img/integrations/slack.png'
import adpLogo from '@/assets/img/integrations/adp.png'
import bambooHRLogo from '@/assets/img/integrations/bamboohr.png'
import gustoLogo from '@/assets/img/integrations/gusto.png'
import justworksLogo from '@/assets/img/integrations/justworks.png'
import paylocityLogo from '@/assets/img/integrations/paylocity.png'
import ripplingLogo from '@/assets/img/integrations/rippling.png'
import trinetLogo from '@/assets/img/integrations/trinet.png'
import zenefitsLogo from '@/assets/img/integrations/zenefits.png'
import spreadsheetImage from '@/assets/img/import_option_spreadsheet.png'

import { toast } from '@/toasts'
import { api } from '@/api'

import customerCommunicationsMixin from '@/mixins/customerCommunicationsMixin'
import slackMixin from '@/mixins/slackMixin'

import finchConnect from '@/components/FinchConnect.vue'

export default {
  components: {
    pageHeader,
    grid,
    gridColumn,
    card,
    cardDescription,
    gImage,
    container,
    loading,
    finchConnect,
  },
  mixins: [customerCommunicationsMixin, slackMixin],
  data() {
    return {
      toast,
      spreadsheetImage,
      slackLogo,
      hrisImportOptions: [
        { provider: 'gusto', src: gustoLogo },
        { provider: 'bamboo_hr', src: bambooHRLogo },
        { provider: 'zenefits', src: zenefitsLogo },
        { provider: 'rippling', src: ripplingLogo },
        { provider: 'justworks', src: justworksLogo },
        { provider: 'adp_workforce_now', src: adpLogo },
        { provider: 'trinet', src: trinetLogo },
        { provider: 'paylocity', src: paylocityLogo },
      ],
      slackIntegrationLoading: false,
      organizationLoading: false,
      slackIntegration: null,
      organization: null,
    }
  },
  computed: {
    loading() {
      return this.slackIntegrationLoading && this.organizationLoading
    },
  },
  mounted() {
    this.getPage()
  },
  methods: {
    async getPage() {
      await this.getOrganization()
      await this.getSlackIntegration()
    },
    async getOrganization() {
      try {
        this.organizationLoading = true
        this.organization = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/organizations/${this.$store.state.organizationId}`
          )
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.organizationLoading = false
      }
    },
    async getSlackIntegration() {
      try {
        this.slackIntegrationLoading = true
        this.slackIntegration = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/organizations/${this.$store.state.organizationId}/slack-integration`
          )
        ).data
      } catch (error) {
        if (error.code !== 404) {
          toast.error(error)
        }
      } finally {
        this.slackIntegrationLoading = false
      }
    },
    selectHris(hris) {
      if (
        this.organization.finchProviderName &&
        !this.organization.finchNeedsAuth
      ) {
        toast.info('Looks like you already have an integration!')
        return
      }
      if (hris === 'other') {
        this.$refs.finch.open({ provider: null, showManual: true })
      } else {
        this.$refs.finch.open({ provider: hris, showManual: false })
      }
    },
    selectSlack() {
      if (this.slackIntegration) {
        this.$router.push({ name: 'import_slack' })
      } else {
        window.location.href = this.buildSlackConnectionUrl({
          name: this.$route.name,
          organizationId: this.organization.id,
          userId: this.$store.state.currentUser.id,
          redirectPath: '/import/slack',
          redirectErrorPath: '/import',
        })
      }
    },
    selectSpreadsheet() {
      // from mixin
      this.openPeopleInfoSpreadsheetTemplate()
    },
    async exchangeCode(code) {
      try {
        await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/vendors/finch/token`,
          { code }
        )
        this.$router.push({ name: 'import_hris' })
      } catch (error) {
        toast.error(error)
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/text.less';

.other {
  padding: 1.5em 0;
}

.integration {
  min-height: 12em;
}

.integration-img {
  max-height: 5em;
  padding: 0 1.5em;
}

.description {
  font-size: @body-font-size;
}

.other-card {
  padding: 0 2em;
  font-size: @title-font-size;
  font-weight: bold;
}
</style>
