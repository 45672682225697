<template>
  <iframe v-if="src" class="finch-iframe" :src="src" />
</template>

<script>
import { api } from '@/api'

export default {
  data() {
    return {
      src: '',
    }
  },
  methods: {
    async open({ provider, showManual }) {
      this.src = (
        await api.get(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/vendors/finch/connect`,
          {
            manual: showManual ? '1' : '0',
            payrollProvider: provider || '',
          }
        )
      ).data
      window.addEventListener('message', this.handleFinchAuth)
    },
    close() {
      window.removeEventListener('message', this.handleFinchAuth)
      this.src = ''
      this.$emit('on-close')
    },
    async handleFinchAuth(event) {
      const { code, error, closed } = event.data
      if (code) {
        this.handleFinchAuthSuccess(code)
        this.close()
      } else if (error) {
        this.handleFinchAuthError(error)
      } else if (closed) {
        this.close()
      }
    },
    handleFinchAuthSuccess(code) {
      this.$emit('on-success', code)
    },
    handleFinchAuthError(error) {
      this.$emit('on-error', error)
    },
  },
}
</script>

<style lang="less" scoped>
.finch-iframe {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
}
</style>
